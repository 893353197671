import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  onClick(translateX: string, top: string): void {
    const slider = document.getElementById('slider');
    const active = document.getElementById('active');

    slider.style.transform = translateX;
    active.style.top = top;
  }
}
