<div class="container">

  <div class="navbar">
    <div class="logo">
      <a href="https://www.instagram.com/furs_kazan/?hl=ru">Furs_kazan</a>
    </div>
<!--    <nav>-->
<!--      <ul>-->
<!--        <li><a routerLink="">Главная</a></li>-->
<!--        <li><a routerLink="/gallery">Наши работы</a></li>-->
<!--        <li><a routerLink="/about">О нас</a></li>-->
<!--      </ul>-->
<!--    </nav>-->
    <div class="user">
      <p>Запись на консультацию</p>
      <img src="../assets/images/whatsapp.png">
      <p> +7 (987) 188-91-34 </p>
    </div>
  </div>

  <router-outlet></router-outlet>

  <div class="sidebar">
<!--    <img src="../assets/images/search.png" class="search-icon">-->
    <div class="social-links">
      <img src="../assets/images/fb.png">
      <img src="../assets/images/ig.png">
    </div>
<!--    <img src="../assets/images/info.png" class="info-icon">-->
  </div>

</div>

<!--<div class="services_container">-->
<!--  <div class="services_wrapper">-->
<!--    <div class="service_wrapper">-->
<!--      <img class="service_img" src="../assets/images/main.jpg"/>-->
<!--      <a class="service_link" href="">Узнать больше ...</a>-->
<!--    </div>-->
<!--    <div class="service_wrapper">-->
<!--      <img class="service_img" src="../assets/images/main.jpg"/>-->
<!--      <a class="service_link" href="">Узнать больше ...</a>-->
<!--    </div>-->
<!--    <div class="service_wrapper">-->
<!--      <img class="service_img" src="../assets/images/main.jpg"/>-->
<!--      <a class="service_link" href="">Узнать больше ...</a>-->
<!--    </div>-->
<!--    <div class="service_wrapper">-->
<!--      <img class="service_img" src="../assets/images/main1.jpg"/>-->
<!--      <a class="service_link" href="">Узнать больше ...</a>-->
<!--    </div>-->
<!--  </div>-->

<!--  <div class="services_wrapper">-->
<!--    <div class="service_wrapper">-->
<!--      <img class="service_img" src="../assets/images/main.jpg"/>-->
<!--      <a class="service_link" href="">Узнать больше ...</a>-->
<!--    </div>-->
<!--    <div class="service_wrapper">-->
<!--      <img class="service_img" src="../assets/images/main.jpg"/>-->
<!--      <a class="service_link" href="">Узнать больше ...</a>-->
<!--    </div>-->
<!--    <div class="service_wrapper">-->
<!--      <img class="service_img" src="../assets/images/main.jpg"/>-->
<!--      <a class="service_link" href="">Узнать больше ...</a>-->
<!--    </div>-->
<!--    <div class="service_wrapper">-->
<!--      <img class="service_img" src="../assets/images/main1.jpg"/>-->
<!--      <a class="service_link" href="">Узнать больше ...</a>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
